<template>
  <el-dialog
    v-model="show"
    custom-class="tree-dialog"
    :title="title"
    :close-on-click-modal="false"
    :z-index="99"
    @close="onClose"
    :modal-class="this.$root.config.theme.isDark() ? 'dark-7' : 'light-7'"
  >
    <div v-if="isFetching" class="tree-dialog-fetching">正在加载数据...</div>
    <div v-else-if="activeList" class="tree-dialog-inner">
      <tree-list
        ref="treeList"
        :initDelay="300"
        :init-list="groupList || initList"
        :select-enabled="true"
        :filter-item="filterItem"
        v-model:active-item="activeItem"
        :update-node-api="updateNodeApi"
        :clone-api="cloneApi"
        @onSelect="onSelect"
        :load="loadNode"
      >
        <template #default="{ data }">
          <document-item
            v-model:title="data.title"
            :select-enabled="true"
            @onClick="onSelectTreeItem(data)"
            :item="data"
          />
        </template>
      </tree-list>
      <group-list
        v-if="groupEnabled"
        :theme="$root.config.theme"
        :is-local="true"
        @change="onOpenGroup"
      />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button class="close" @click="() => (this.show = false)"> 取消 </el-button>
        <el-button type="primary" :disabled="!activeItem" @click="onSubmit">
          选择
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import TreeList from "./TreeList";
import DocumentItem from "./DocumentItem";
import GroupList from "../document/GroupList";

export default {
  components: { GroupList, DocumentItem, TreeList },
  inject: ["cloneTreeItem"],
  props: {
    needLoadData: { type: Boolean, default: false },
    groupEnabled: { type: Boolean, default: false },
    isLocal: { type: Boolean, default: true },
    initList: { type: Array, default: () => undefined },
    loadNode: { type: Function, default: null },
    theme: { type: String, default: "" },
    filterItem: { type: Object, default: undefined },
    dialogType: { type: Number, default: 0 },
    updateNodeApi: { type: Function, default: undefined },
    cloneApi: { type: Function, default: undefined },
    onSelect: { type: Function, default: undefined },
    saveCurGroup: { type: Boolean, default: false },
  },
  watch: {
    dialogType(val) {
      if (val !== 0) {
        this.title =
          (val === 1 && "移动模型到") ||
          (val === 2 && "复制模型到") ||
          (val === 3 && "恢复模型到") ||
          (val === 4 && "转存模型到") ||
          (val === 5 && "导入模型到") ||
          "";
      }
      this.show = val !== 0;
      this.activeList = true;
      if (val === 0) {
        this.activeItem = null;
      }
    },
  },
  data() {
    return {
      // 0 normal 1 submitting
      submitState: 0,
      list: [],
      show: false,
      activeList: false,
      activeItem: null,
      title: "",
      isFetching: false,
      groupList: undefined,
      curGroup: undefined,
    };
  },
  mounted() {
    if (this.needLoadData) {
      this.isFetching = true;
      this.$root.loadTreeList().then(() => {
        this.isFetching = false;
      });
    }
  },
  methods: {
    onOpenGroup(group) {
      this.changeGroupList(group);
    },
    async changeGroupList(group) {
      this.curGroup = group;
      // if (!this.$store.state.isLogin ||  this.loginState !== LoginState.hasLogin) return;
      if (!group) {
        this.groupList = undefined;
        return;
      }
      // 可分组获取
      const list = [];
      if (group.itemList) {
        group.itemList.forEach((item) => {
          const node = this.$root.cloudTreeMap[item.articleId];
          if (node && !node.parent) {
            list.push(node);
          }
        });
      }
      this.groupList = [
        {
          id: "root",
          hasChildren: list.length > 0,
          title: group.groupName,
          children: list,
        },
      ];
    },
    onClose() {
      clearTimeout(this.timer);
      this.$emit("update:dialogType", 0);
      this.$emit("update:filterItem", null);
      this.timer = setTimeout(() => {
        this.activeList = false;
      }, 200);
    },
    onSelectTreeItem(item) {
      this.activeItem = item;
      this.$refs.treeList.expand(item);
    },
    tips(type, message) {
      if (this.timeOutTips) {
        window.clearTimeout(this.timeOutTips);
      }
      this.timeOutTips = window.setTimeout(() => {
        this.$message({
          message: message,
          type: type,
          grouping: true,
        });
      }, 500);
    },
    async onSubmit() {
      if (this.submitState === 1) return;
      if (!this.activeItem || !this.filterItem) {
        return;
      }
      this.submitState = 1;
      if (this.dialogType === 3 || this.dialogType === 4 || this.dialogType === 5) {
        this.$emit("submit", this.activeItem, this.curGroup);
      } else if (this.dialogType === 1) {
        this.tips("info", "正在移动");
        // console.log('------', this.filterItem, this.activeItem, this.curGroup)
        if (this.curGroup && this.activeItem.id === "root") {
          const idx = this.curGroup.itemList
            ? this.curGroup.itemList.findIndex((r) => r.articleId === this.filterItem.id)
            : -1;
          if (idx !== -1) {
            // 分组中存在，不需要添加
            return;
          }
          // 添加到分组
          await this.$root.addGroupItem(
            this.curGroup.id,
            this.filterItem.id,
            this.curGroup.itemList?.length || 0
          );
        } else {
          //移动
          await this.$refs.treeList.moveToInner(this.filterItem, this.activeItem);
        }

        this.tips("success", "移动模型成功");
      } else {
        this.tips("info", "正在复制");

        if (this.cloneTreeItem) {
          this.cloneTreeItem(this.filterItem, this.activeItem, this.curGroup);
        } else {
          const copyTimer = setTimeout(() => {
            this.tips(
              "info",
              "节点内容较多，正在后台拼命复制，复制完成会有系统提醒，您可以继续使用模型树！"
            );
          }, 1000);
          const curGroup = this.curGroup;
          this.$refs.treeList.cloneItem(this.filterItem, this.activeItem).then((s) => {
            clearTimeout(copyTimer);
            this.$root.tips("success", "复制模型成功");
            if (curGroup) {
              this.$root.addGroupItem(curGroup.id, s.id);
            }
          });
        }
        // this.tips("info", "正在复制");
        // const s = await this.$refs.treeList.cloneItem(this.filterItem, this.activeItem);
        // this.tips("success", "复制模型成功");
        // if (this.curGroup) {
        //   await this.$root.addGroupItem(this.curGroup.id, s.id);
        // }
      }
      this.show = false;
      this.submitState = 0;
    },
  },
};
</script>
<style>
.tree-dialog-fetching {
  text-align: center;
  color: #999;
  padding: 10px 0;
  min-height: 300px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border: 1px solid #dedee1;
}

.tree-dialog-inner {
  position: relative;
}

.tree-dialog .grout-icon {
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 9;
  cursor: pointer;
}
</style>
