<template>
  <div class="tree-content">
    <div
      class="tree-label context-menu"
      :class="{ 'emphasis-color': isMarkEmphasis }"
    >
      <div v-if="iconType" class="tree-icon d-center">
        <svg-icon
          :icon-class="iconType.icon"
          class-name="size-3 icon-color-white"
          :class="[
            { active: item.active && !isMarkEmphasis },
            iconType.className || '',
          ]"
        />
      </div>
      <div v-else class="tree-icon d-center">
        <svg-icon
          v-if="!item.parent"
          icon-class="new-02"
          class-name="size-3 icon-color-white"
        />
        <svg-icon
          v-else
          icon-class="new-04"
          class-name="size-4 icon-color-white"
        />
      </div>
      <div
        ref="input"
        class="tree-title"
        placeholder="请输入名称"
        :contenteditable="isEdit"
        @blur="onBlur"
        @keydown="onKeydown"
        @focus="onFocus"
      >
        {{ value }}
      </div>
      <div class="d-flex link-icon" v-if="!isEdit && item.link2">
        <svg-icon
          v-if="item.link2"
          icon-class="link-single"
          :color="
            $root.config.mindThemeColor === 'green' ? '#00FA92' : '#8664FF'
          "
        ></svg-icon>
      </div>
      <!--      <div class="d-flex link-icon" v-if="!isEdit && (item.link1 || item.link2) && $root.showLinkIcon">-->
      <!--        <svg-icon v-if="item.link1" icon-class="link-single"></svg-icon>-->
      <!--        <svg-icon v-if="item.link2" icon-class="link-double"></svg-icon>-->
      <!--      </div>-->
    </div>
    <span class="tree-right" v-if="isEdit && !selectEnabled && !hideRight">
      <el-icon class="tree-edit-icon">
        <Finished />
      </el-icon>
    </span>
    <span
      class="tree-right"
      v-else-if="!selectEnabled && !hideRight"
      :class="isMobile ? 'show-block' : ''"
    >
      <el-button
        class="button-001"
        link
        size="small"
        @click="onMenu($event, 'new')"
        v-if="!isMobile && menuType === 1"
      >
        <svg-icon icon-class="new-31" class="d-center size-1"></svg-icon>
      </el-button>
      <el-button
        class="button-002"
        link
        size="small"
        @click="onMenu($event, 'map')"
        v-if="!isMobile && menuType === 1"
      >
        <svg-icon icon-class="new-11" class="d-center size-1"></svg-icon>
      </el-button>
      <el-button link size="small" @click="onMenu($event, 'more')">
        <svg-icon icon-class="new-more" class="d-center size-1"></svg-icon>
      </el-button>
    </span>
  </div>
</template>
<script>
import { iconMaps } from "../../../../utils/TreeConstants";
import TreeInput from "./TreeInput";

export default {
  props: {
    isEdit: { type: Boolean, default: false },
    menuType: { type: Number, default: 1 },
    isMobile: { type: Boolean, default: false },
    title: { type: String, default: undefined },
    item: { type: Object, default: undefined },
    selectEnabled: { type: Boolean, default: false },
    hideRight: { type: Boolean, default: false },
  },
  components: {
    TreeInput,
  },
  computed: {
    iconType: {
      get() {
        return iconMaps[this.$getIconType(this.item)];
      },
    },
    isMarkEmphasis: {
      get() {
        return this.$isMarkEmphasis(this.item);
      },
    },
    value: {
      get() {
        if (this.title === "新建模型" && this.isEdit) {
          return "";
        }
        return this.title.replace(/\n/g, "");
      },
      set(val) {
        this.$emit("update:title", val);
      },
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  mounted() {},
  watch: {
    isEdit(val) {
      if (val) {
        this.focus();
      }
    },
  },
  methods: {
    focus() {
      const treeItem = this.$el.parent("tree-item");
      if (treeItem) treeItem.classList.add("tree-edit");

      this.$nextTick(() => {
        this.$refs.input.scrollLeft = 10000;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 400);
      });
    },
    onMenu(e, type) {
      e.stopPropagation();
      this.$emit("onMenu", this, type, {
        clientX: e.clientX,
        clientY: e.clientY,
      });
    },
    onKeydown(e) {
      if (e.key === "Enter") {
        this.$refs.input.blur();
      }
    },
    onFocus() {
      clearTimeout(this.timer);
      this.timer = undefined;
      this.$root.cursorElement = true;
      this.tempTitle = this.title;
      // if (this.tempTitle === "新建模型") {
      //   this.selectAll();
      // } else {
      //   this.focusEnd();
      // }
      this.focusEnd();
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.classList.add("tree-edit");
      this.$emit("input-focus");
    },
    selectAll() {
      if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(this.$refs.input);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    focusEnd() {
      // this.el.focus();
      if (window.getSelection) {
        //ie11 10 9 ff safari
        const range = window.getSelection(); //创建range
        range.selectAllChildren(this.$refs.input); //range 选择box2下所有子内容
        range.collapseToEnd(); //光标移至最后
      }
    },
    onClickInput(e) {
      e.stopPropagation();
    },
    onBlur() {
      this.$emit("update:isEdit", false);
      this.$refs.input.scrollLeft = 0;
      const text = this.$refs.input.innerText;
      this.$root.cursorElement = false;
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.classList.remove("tree-edit");

      if (this.title !== text && text) {
        if (text) {
          this.value = text;
        }
        this.$emit("onRename", text);
      } else {
        this.$refs.input.innerText = this.title;
      }
    },
    onDoubleClick() {},
    onClick(e) {
      if (this.selectEnabled) {
        this.$emit("onClick");
        return;
      }
      if (this.isEdit) {
        e.stopPropagation();
        return;
      }
      if (!this.timer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$emit("onClick");
          this.timer = null;
        }, 200);
      } else {
        clearTimeout(this.timer);
        this.timer = null;
        let treeItem = this.$refs.input.parent("tree-item");
        if (treeItem) treeItem.classList.add("tree-edit");
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.emphasis-color {
  input,
  .tree-icon svg {
    color: #de4e4e !important;
  }
}
</style>
