<template>
  <div ref="mindGraph" :class="['mind-map', theme]" v-resize="onResize">
    <div ref="mindmap" class="mindmap-container" tabindex="1" />
    <div
      ref="mindmapInput"
      class="mindmap-label-edit"
      spellCheck="false"
      style="display: none"
      contenteditable="true"
    />
    <div
      v-show="!barExpand && mode === 'edit' && !isTransfer"
      @click="barExpand = true"
      class="bar-expand"
    >
      <svg-icon icon-class="038" class-name="svg-small" />
    </div>
    <ul
      v-show="showToolbar && barExpand"
      class="action-bar shadow left-bottom bgblur thin-border"
      :class="[{ 'bottom-center': $root.config.mindToolbarPosition === 'bottom' }]"
      ref="toolbarRef"
    >
      <!-- :class="[{ 'bottom-center': $root.config.mindToolbarPosition === 'bottom' },]" -->
      <li class="button max480" code="hideToolbar">
        <el-tooltip effect="dark" content="隐藏工具栏" :placement="placement">
          <svg-icon v-if="placement === 'top'" icon-class="037" class-name="svg-small" />
          <svg-icon v-else icon-class="036" class-name="svg-small" />
        </el-tooltip>
      </li>
      <!-- <li class="button max480" code="back">
        <el-tooltip effect="dark" content="返回" :placement="placement"
          ><svg-icon icon-class="022" class-name="svg-small" />
        </el-tooltip>
      </li>
      <li class="button max480" code="openCurMindMap">
        <el-tooltip effect="dark" content="文档模式" :placement="placement">
          <svg-icon icon-class="011" class-name="svg-small" />
        </el-tooltip>
      </li> -->
      <!-- <li class="button max480" code="changeEdgeShowLabel">
        <el-tooltip effect="dark" content="标签模式" :placement="placement">
          <el-icon>
            <Setting />
          </el-icon>
        </el-tooltip>
      </li> -->
      <!-- <li class="button max480" code="changeMindStyle">
        <el-tooltip effect="dark" content="自定义导图颜色" :placement="placement">
          <el-icon>
            <Setting />
          </el-icon>
        </el-tooltip>
      </li> -->
      <!-- <li class="button max480" code="hideEdge">
        <el-tooltip effect="dark" content="显示/隐藏双链" :placement="placement">
          <svg-icon icon-class="020" class-name="svg-small" />
        </el-tooltip>
      </li> -->
      <li class="button max480" code="downloadPage">
        <el-tooltip effect="dark" content="下载图片" :placement="placement">
          <svg-icon icon-class="023" class-name="svg-small" />
        </el-tooltip>
      </li>
      <!-- <li class="button max480" code="toolbarEdgeMenu">
        <el-tooltip effect="dark" content="双链" :placement="placement">
          <svg-icon icon-class="link-single" class-name="svg-small" />
        </el-tooltip>
      </li> -->
      <li class="button" code="fitCenter">
        <el-tooltip effect="dark" content="居中显示" :placement="placement">
          <svg-icon icon-class="024" class-name="svg-small" />
        </el-tooltip>
      </li>
      <!-- <li class="button max480" code="zoomOut">
        <el-tooltip effect="dark" content="放大" :placement="placement">
          <el-icon>
            <ZoomIn />
          </el-icon>
        </el-tooltip>
      </li>
      <li class="button max480" code="zoomIn">
        <el-tooltip effect="dark" content="缩小" :placement="placement">
          <el-icon>
            <ZoomOut />
          </el-icon>
        </el-tooltip>
      </li> -->
      <!-- <li
        v-if="isMobile && showNodeMenu"
        class="button max480"
        @click="onShowNodeMenu"
      >
        <el-tooltip effect="dark" content="菜单" :placement="placement">
          <el-icon><More /></el-icon>
        </el-tooltip>
      </li> -->
      <li class="button max480" code="setting">
        <el-tooltip effect="dark" content="导图设置" :placement="placement">
          <el-icon>
            <Setting />
          </el-icon>
        </el-tooltip>
      </li>
      <li
        v-if="isMobile && showNodeMenu && mobileMenusType !== 'float'"
        class="button max480 pos-relative"
        @click="onShowNodeMenu"
      >
        <i class="icon-gengduo3"></i>
      </li>
    </ul>
    <div class="gp-create-edge" ref="edgeTip">
      <div class="gp-tip">
        <div class="d-end title">
          <div class="d-center mind-title">
            <svg-icon icon-class="029" class-name="svg-small" />
            关联双链
          </div>
          <div
            class="btn btn-font-gray d-center btn-close"
            style="margin-right: 16px"
            @click="onCancelEdgeTip"
          >
            <el-icon>
              <CloseBold />
            </el-icon>
          </div>
        </div>
        <div class="gp-inner">
          <div></div>
          <div
            ref="edgeSourceLabel"
            class="gp-edit"
            :placeholder="placeholder"
            spellCheck="false"
            contenteditable="true"
            @focus="placeholder = ''"
            @blur="placeholder = '加标签'"
          ></div>
          <div></div>
          <div ref="sourceLabel" class="gp-node left-node"></div>
          <div class="gp-line">
            <div class="gp-up"></div>
            <div class="gp-down"></div>

            <!-- <div class="line"><div class="arrow"></div></div>
            <div class="line linetop"><div class="arrow arrowleft"></div></div> -->
          </div>
          <div ref="targetLabel" class="gp-node right-node"></div>
          <div></div>
          <div
            ref="edgeTargetLabel"
            class="gp-edit"
            :placeholder="placeholder2"
            spellCheck="false"
            contenteditable="true"
            @focus="placeholder2 = ''"
            @blur="placeholder2 = '加标签'"
          ></div>
          <div></div>
        </div>
        <div class="d-end gp-buttons">
          <div class="gp-btn solid-black" @click="onCancelEdgeTip">取消</div>
          <div class="gp-btn primary" @click="onSubmitEdgeTip">确定</div>
        </div>
      </div>
    </div>
    <div class="node-menu" ref="nodeMenuRef" v-if="isMobile">
      <div class="node-menu-content">
        <!-- <div class="mb-menu-item" @click="onNodeMenu('detail')" v-if="menuType !== 1">
          <svg-icon icon-class="detail" />
          <div>详情</div>
        </div> -->
        <div class="mb-menu-item" @click="onNodeMenu('edit')">
          <svg-icon icon-class="edit" />
          <div>编辑</div>
        </div>
        <div class="mb-menu-item" @click="onNodeMenu('delete')">
          <svg-icon icon-class="delete" />
          <div>删除</div>
        </div>
        <div class="mb-menu-item" @click="onNodeMenu('link')" v-if="menuType !== 1">
          <svg-icon icon-class="link" />
          <div>双链</div>
        </div>
      </div>
      <div class="menu-angle"></div>
    </div>
  </div>
  <el-dialog
    v-model="changeMindStyleVisible"
    title="自定义思维导图颜色"
    width="60%"
    custom-class="tree-dialog"
    draggable
  >
    <mind-style ref="mindStyleRef" @close="changeMindStyleVisible = false" />
  </el-dialog>

  <el-dialog
    v-if="!hideToolbar"
    v-model="showDownload"
    width="380px"
    custom-class="download-map"
    @close="
      () => {
        this.showDownload = false;
      }
    "
  >
    <div class="title">下载导图</div>
    <div class="subtitle">选择下载导图清晰度</div>
    <div class="des">默认清晰度：当前页面缩放清晰度</div>
    <div class="d-end download-btns">
      <el-button class="btn-cancel" @click="customDownload"> 普通下载 </el-button>
      <el-button class="btn-gray" @click="highDownload"> 高清下载</el-button>
    </div>
    <div v-if="isReadyDownload" class="pos-absolute download-loading">
      <div class="ui-loading small"></div>
    </div>
  </el-dialog>
</template>
<script>
import {
  MindGraph,
  NodeType,
  Menu,
  ToolBar,
  Minimap,
  MindmapEvent,
  EventName,
  resizeObserver,
  isMobile,
} from "../../../../components/antv-mindmap/mxs-mindmap.es";

const isArray = (arg) =>
  Object.prototype.toString.call(arg).toLowerCase().indexOf("array") > 5;
const isObject = (arg) =>
  Object.prototype.toString.call(arg).toLowerCase() === "[object object]";

import { cut, copy } from "./copyNode";
import MindStyle from "./MindStyle";
import SvgIcon from "../../../../svgIcon/index.vue";
import store from "../../../../store/index";
import { Platform } from "../../../../utils/utils";
import { defaultTheme } from "../../../../utils/constaints";
import Confirm from "../widget/Confirm";
import { CanvasMenus, EdgeMenus, getMenusDom, NodeMenus } from "./ToolsConfig";
import { getDownloadStyle } from "./download";
import { MenuActions } from "./menu";

export default {
  props: {
    hideToolbar: { type: Boolean, default: false },
    selectItem: { type: Object, default: undefined },
    resizeCenter: { type: Boolean, default: false },
    edges: { type: Array, default: () => [] },
    allowChangeRoot: { type: Boolean, default: false },
    showEdgesChangeRoot: { type: Boolean, default: false },
    correlationChangeToRoot: { type: Boolean, default: false },
    theme: { type: String, default: defaultTheme },
    mode: { type: String, default: "default" },
    layoutConfig: {
      type: Object,
      default: () => {},
    },
    defaultNodeStyle: {
      type: Object,
      default: () => {},
    },
    defaultEdgeStyle: {
      type: Object,
      default: () => {},
    },
    defaultEdgeLinkStyle: {
      type: Object,
      default: () => {},
    },
    vGap: { type: Number },
    hGap: { type: Number },
    initShowDepth: { type: Number, default: 3 },
    // 脑图数
    modelValue: { required: true },
    sharpCorner: Boolean,
    scaleRatio: { type: Number, default: 1 },
    clickEdgeShowLabel: { type: Boolean, default: false },
    showCorrelationsNode: { type: Boolean, default: false },
    getNodes: { type: Function, default: () => [] },
    showToolbar: { type: Boolean, default: false },
    wheel: { type: Boolean, default: true },
    quadtreeType: { type: String, default: "ver" },
    isTransfer: { type: Boolean, default: false },
  },
  components: {
    Confirm,
    MindStyle,
    SvgIcon,
  },
  inject: [
    "openEditor",
    "isShowMDEditor",
    "saveStatus",
    "moveArticle",
    "menuOpenMindMap",
    "changeZIndex"
  ],
  data() {
    const canWheel = this.$props.wheel;
    return {
      menuType: 0,
      tempData: undefined,
      showDownload: false,
      isReadyDownload: false,
      isMobile: Platform.isMobile,
      mobileMenusType: "float",
      showNodeMenu: false,
      tempNode: undefined,

      ctrl: store.state.sys === "mac" ? "⌘" : "Ctrl",
      changeMindStyleVisible: false,
      id: "mxs-mindmap_container",
      graph: null,
      defaultMode: [
        {
          type: "behavior-canvas",
          shouldBegin: (ctrl) => {
            if (ctrl) {
              return true;
            } else {
              return canWheel;
            }
          },
        },
        "drag-canvas",
        "behavior-default-node",
      ],
      //记录点击过的node
      nodeSelectRecord: [],
      shortcuts: [
        {
          key: "Tab",
          label: "添加子节点",
          Event: (graph) => {
            if (
              graph._curSelectedNode &&
              graph._curSelectedNode.getModel().type === NodeType.defaultNode
            ) {
              this.handleClickCode("addChild", graph._curSelectedNode);
            }
          },
        },
        {
          key: "Enter",
          label: "添加同级节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            if (model.sortId !== "0-0") {
              if (model.collapsed) {
                this.handleClickCode("expand", graph._curSelectedNode);
              } else {
                const parent = graph._curSelectedNode.get("parent");
                let sort;
                if (parent) {
                  const p = this.$root.findItemById(parent.getModel().id);
                  sort = p.children.findIndex((r) => r.id === model.id);
                  sort = sort < 0 ? undefined : sort;
                }

                this.handleClickCode("addChild", parent, sort + 1);
              }
            }
          },
        },
        {
          key: "Delete",
          label: "删除当前节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            this.handleClickCode("delete", graph._curSelectedNode);
          },
        },
        // {
        //   key: "Backspace",
        //   label: "删除当前节点",
        //   Event: (graph) => {
        //     if (this.clickTimer) {
        //       return;
        //     }
        //     if (!graph._curSelectedNode)
        //       return;
        //     const model = graph._curSelectedNode.get("model");
        //     if (model.isFocus) return;
        //     this.handleClickCode("delete", graph._curSelectedNode);
        //   },
        // },
        {
          key: "ArrowDown",
          label: "选择下一个兄弟节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            const siblings = graph._curSelectedNode.get("parent").get("children");
            const index = siblings.findIndex(
              (item) => item.get("id") === graph._curSelectedNode.get("id")
            );
            if (!siblings[index + 1]) return;
            this.$emit(EventName.change, {
              type: MindmapEvent.nodeSelect,
              options: { node: siblings[index + 1] },
            });
          },
        },
        {
          key: "ArrowUp",
          label: "选择上一个兄弟节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            const siblings = graph._curSelectedNode.get("parent").get("children");
            const index = siblings.findIndex(
              (item) => item.get("id") === graph._curSelectedNode.get("id")
            );
            if (!siblings[index - 1]) return;
            this.$emit(EventName.change, {
              type: MindmapEvent.nodeSelect,
              options: { node: siblings[index - 1] },
            });
          },
        },
        {
          key: "ArrowLeft",
          label: "选择父节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            const parent = graph._curSelectedNode.get("parent");
            if (!parent) return;
            if (parent.getModel().id === "hide-root") {
              return;
            }
            this.$emit(EventName.change, {
              type: MindmapEvent.nodeSelect,
              options: { node: parent },
            });
          },
        },
        {
          key: "ArrowRight",
          label: "选择第一个子节点",
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            const children = graph._curSelectedNode?.get("children") ?? [];
            if (!children[0]) return;
            this.$emit(EventName.change, {
              type: MindmapEvent.nodeSelect,
              options: { node: children[0] },
            });
          },
        },
        {
          key: "x",
          label: "剪切节点",
          control: ["cmd", "ctrl"],
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            cut(graph._curSelectedNode);
            this.$root.tips("success", "已剪切");
          },
        },
        {
          key: "c",
          label: "复制节点",
          control: ["cmd", "ctrl"],
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            copy(graph._curSelectedNode);
            this.$root.tips("success", "已复制");
          },
        },
        {
          key: "v",
          label: "粘贴节点",
          control: ["cmd", "ctrl"],
          Event: (graph) => {
            if (!graph._curSelectedNode) return;
            const model = graph._curSelectedNode.get("model");
            if (model.isFocus) return;
            this.handleClickCode("paste", graph._curSelectedNode);
          },
        },
      ],
      editMode: [
        {
          type: "behavior-canvas",
          shouldBegin: (ctrl) => {
            if (ctrl) {
              return true;
            } else {
              return canWheel;
            }
          },
        },
        "drag-canvas",
      ],
      placeholder: "加标签",
      placeholder2: "加标签",
      barExpand: this.$store.state.barExpand,
      downloadTimeout: null,
    };
  },
  computed: {
    hideEdge: {
      get() {
        if (this.$root.config.mindHideEdgeLink === "true") {
          return true;
        } else {
          return false;
        }
      },
    },
    placement: {
      get() {
        if (this.$root.config.mindToolbarPosition === "bottom") {
          return "top";
        } else {
          return "right";
        }
      },
    },
  },
  watch: {
    selectItem(val, old) {
      if (val && (!old || val.id !== old.id)) {
        const idx = this.nodeSelectRecord.findIndex((r) => r.id === val.id);
        if (idx !== -1) {
          this.nodeSelectRecord.splice(idx, 1);
        }
        this.nodeSelectRecord.push(val);
        this.graph?.editSelectedNodeById(val.id);
      }
    },
    quadtreeType(val, old) {
      if (val !== old) {
        this.graph.changeQuadtreeType(val);
      }
    },
    clickEdgeShowLabel(val, old) {
      if (val !== old) {
        this.graph?.changeEdgeShowLabel(val);
      }
    },
    hideEdge(val, old) {
      if (val !== old) {
        this.graph?.setEdgeVisible(val);
      }
    },
    mode(val, old) {
      if (val !== old) {
        if (this.graph) {
          this.graph.setMode(val);
        }
      }
    },
    modelValue: {
      handler(val, old) {
        if (isArray(val) && !val.length) return;
        if (isObject(val) && !Object.keys(val).length) return;
        if (val) {
          if ((old && old.id !== val.id) || !old) {
            this.nodeSelectRecord = [];
            this.resetGraph();
          }
        } else if (!val && this.graph) {
          this.graph.destroy();
          this.graph = undefined;
        }
      },
      immediate: true,
    },
    "$props.edges": {
      handler(val) {
        if (this.graph) this.graph.updateEdges(val || []);
      },
      immediate: true,
    },
    "$props.edit": {
      handler(val) {
        this.graph.changeEditMode(val);
      },
    },
    "$props.defaultNodeStyle": {
      handler() {
        this.changeMindTheme();
      },
    },
    barExpand(val, old) {
      if (val !== old) {
        this.$store.commit("updatebarExpand", this.barExpand);
      }
    },
    "$root.cursorElement": {
      handler(val) {
        this.graph.selectEditEnabled = !val;
      },
    },
  },
  directives: {
    resize: resizeObserver,
  },
  mounted() {
    this.editMode.push({
      type: "behavior-shortcut",
      hotList: this.shortcuts,
    });
    this.editMode.unshift("behavior-pc");
    if (this.isMobile) {
      this.editMode.unshift("behavior-mobile");
      this.onCancelMenu = this._onCancelMenu.bind(this);
    } 
    // else {
    //   this.editMode.push({
    //     type: "behavior-shortcut",
    //     hotList: this.shortcuts,
    //   });
    //   this.editMode.unshift("behavior-pc");
    // }
    this.initGraph();
    // this.initTools();
  },
  beforeUnmount() {
    // if (this.mode !== "default") {
    //   this.graph.destroy();
    // }
    this.graph?.destroy();
    clearTimeout(this.tempTimer);
    this.graph = null;
  },
  methods: {
    onNodeMenu(type) {
      const menuType = this.menuType;
      this.hideMenu();
      if (type === "edit") {
        clearTimeout(this.editTimer);
        if (menuType === 0) {
          this.graph.editItem(this.graph._curSelectedNode);
        } else if (menuType === 1) {
          this.menuType = 99;
          const source = this.tempNode.getSource().getModel();
          const target = this.tempNode.getTarget().getModel();
          const model = this.tempNode.getModel();
          this.sourceLabel = source.title || "";
          this.targetLabel = target.title || "";
          this.$refs.edgeTargetLabel.innerText = model.label2;
          this.$refs.edgeSourceLabel.innerText = model.label1;
          this.changeZIndex(9);
          this.$refs.edgeTip.style.display = "flex";
        }
      } else if (type === "link") {
        this.graph.editSelectLink(this.graph._curSelectedNode, 2);
      } else if (type === "delete") {
        if (menuType === 0) {
          this.handleClickCode("delete", this.graph._curSelectedNode);
        } else {
          this.handleClickCode("labelDelete", this.graph._curSelectedNode);
        }
      } else {
        if (menuType === 1) {
          this.graph.deleteEdge(this.tempNode);
          return;
        }
        this.$emit("menu", type);
      }
    },
    getDownloadName() {
      let title = "导图";
      if (this.modelValue.title) {
        title = this.modelValue.title;
      } else if (this.modelValue.info.title) {
        title = this.modelValue.info.title;
      }
      return title;
    },
    highDownload() {
      this.isReadyDownload = true;
      const style = getDownloadStyle(this.getDownloadName(), 5, this.$root.config.theme);

      this.graph.download({
        ...style,
        delay: 300,
        onFinished: () => {
          this.isReadyDownload = false;
          this.showDownload = false;
        },
      });
    },
    customDownload() {
      this.isReadyDownload = true;
      const style = getDownloadStyle(this.getDownloadName(), 1, this.$root.config.theme);
      this.graph.download({
        ...style,
        expandAll: false,
        delay: 300,
        onFinished: () => {
          this.isReadyDownload = false;
          this.showDownload = false;
        },
      });
    },
    getDataURL() {
      let backgroundColor = "#FFF";
      if (this.$root.config.theme.includes("dark")) {
        backgroundColor = "#0b0b17";
      }
      this.graph?.toFullDataURL(
        (res) => {
          this.$emit(EventName.change, {
            type: "insertEditor",
            options: { dataURL: res },
          });
        },
        "image/png",
        {
          backgroundColor: backgroundColor,
          padding: 30,
        }
      );
    },
    _onCancelMenu() {
      if (this.nodeDom) this.nodeDom.remove();
      this.nodeDom = null;
    },
    onShowNodeMenu(e) {
      e.preventDefault();
      if (this.nodeDom) {
        this._onCancelMenu();
        window.removeEventListener("click", this.onCancelMenu);
        return;
      }
      const isEdge = this.tempNode.get("type") === "edge";
      this.onCancelMenu();
      window.removeEventListener("click", this.onCancelMenu);
      if (!isEdge) {
        this.nodeDom = this.getNodeMenusDom(this.tempNode);
      } else {
        this.nodeDom = this.getEdgeMenusDom(this.tempNode);
      }
      this.nodeDom.className = "mind-menu shadow bounceIn thin-border bgblur menu-mobile";
      if (this.$root.config.mindToolbarPosition !== "bottom") {
        this.nodeDom.classList.add("menu-mobile-right");
      }
      e.target.appendChild(this.nodeDom);
      this.nodeDom.querySelectorAll("li").forEach((el) => {
        el.addEventListener("click", (e) => {
          e.stopPropagation();
          const code = e.target.getAttribute("code");
          if (code === "correlations") {
            return;
          }
          let node = this.tempNode;
          if (code === "correlation") {
            node = this.graph.findById(e.target.getAttribute("node-id"));
          }
          this.handleClickCode(code, node);
          this.onCancelMenu();
          if (code === "changeRoot" || code === "delete" || code === "addChild") {
            this.showNodeMenu = false;
          }
        });
      });
      setTimeout(() => {
        window.addEventListener("click", this.onCancelMenu, { once: true });
      }, 50);
    },
    onCancelEdgeTip() {
      this.$refs.edgeTip.style.display = "none";
      this.changeZIndex(1);
      this.tempOptions = undefined;
      this.tempLinkId = undefined;
      this.$refs.sourceLabel.innerText = "";
      this.$refs.targetLabel.innerText = "";
    },
    onSubmitEdgeTip() {
      if (this.tempLinkId) {
        const options = {};
        let isModify = false;
        if (this.$refs.edgeSourceLabel.innerText !== this.tempOptions.label1) {
          options.label1 = this.$refs.edgeSourceLabel.innerText;
          isModify = true;
        }
        if (this.$refs.edgeTargetLabel.innerText !== this.tempOptions.label2) {
          options.label2 = this.$refs.edgeTargetLabel.innerText;
          isModify = true;
        }
        if (isModify) {
          this.graph.modifyEdgeLabel(this.tempLinkId, options);
        }
      } else {
        this.tempOptions.label1 = this.$refs.edgeSourceLabel.innerText;
        this.tempOptions.label2 = this.$refs.edgeTargetLabel.innerText;
        this.graph.editCreateEdge(this.tempOptions, 2);
      }
      this.onCancelEdgeTip();
    },
    createEdge(options) {
      this.tempLinkId = undefined;
      this.tempOptions = options.data;
      if (options.type !== 2) {
        this.graph.editCreateEdge(options.data, options.type);
      } else {
        this.$refs.sourceLabel.innerText = options.sourceData.title || "";
        this.$refs.targetLabel.innerText = options.targetData.title || "";
        this.$refs.edgeTargetLabel.innerText = "";
        this.$refs.edgeSourceLabel.innerText = "";
        this.changeZIndex(9);
        this.$refs.edgeTip.style.display = "flex";
      }
    },
    modifyEdge(node) {
      const model = node.getModel();
      if (model.labelType === 2) {
        this.tempOptions = undefined;
        this.tempLinkId = model.id;
        this.tempOptions = {
          label1: model.label1,
          label2: model.label2,
        };
        this.$refs.sourceLabel.innerText =
          this.graph.findDataById(model.source).title || "";
        this.$refs.targetLabel.innerText =
          this.graph.findDataById(model.target).title || "";
        this.$refs.edgeSourceLabel.innerText = model.label1 || "";
        this.$refs.edgeTargetLabel.innerText = model.label2 || "";
        this.changeZIndex(9);
        this.$refs.edgeTip.style.display = "flex";
      } else {
        this.graph.edgeEditLabel(node);
      }
    },
    onResize() {
      if (!this.$refs.mindmap || !this.graph) return;
      const width = this.$refs.mindmap.offsetWidth;
      const height = this.$refs.mindmap.offsetHeight;
      this.graph.get("canvas").changeSize(width, height);
      this.graph.set("width", width);
      this.graph.set("height", height);
      if (this.resizeCenter) {
        this.graph?.editFitCenter();
      }
    },
    resetGraph() {
      if (this.graph) {
        this.graph.destroy();
        this.graph = undefined;
      }
      this.initGraph();
    },
    initTools() {
      if (!this.graph) return;
      if (!this.hideToolbar) {
        this.addToolbar();
      }
      if (!this.isMobile) {
        this.addNodeMenu();
      }
      this.addMindmap();
    },
    getValues(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },
    getNodeMenusDom(node) {
      const options = {};

      options.config = {
        //默认开启的设置
        closeNodeMap: false,
        openEditor: true,
        collapsed: true,
        expand: false,
        link: false,
        link2: true,
        correlations: true,
        showEdges: true,
        quadtreeNodes: true,
        hideParent: true,
        aiEnglish: false,
        goBackUp: false,
      };
      if (this.mode === "default") {
        options.config.collapsed = false;
        options.config.correlations = false;
        options.config.showEdges = false;
        options.config.quadtreeNodes = false;
        options.config.link2 = false;
        options.config.hideParent = false;
        return getMenusDom(NodeMenus, this.graph, node, options);
      }
      if (
        this.$root.config.mindMenuButtons &&
        this.$root.config.mindMenuButtons.length > 0
      ) {
        this.$root.config.mindMenuButtons.forEach((key) => (options.config[key] = true));
      } else {
        //为设置为空时默认开启
        options.config = {
          ...options.config,
          delete: true,
          changeIconType: true,
          expandAll: true,
          changeRoot: true,
        };
      }
      if (this.$root.config.aiEnglish === "true") {
        options.config.aiEnglish = true;
      }
      options.config.openEditor =
        options.config.openEditor &&
        !(
          this.$root.config.alwaysEditor === "true" ||
          this.$parent.$parent.$parent.showMDEditor === true
        );

      const model = node.getModel();

      const item = this.$root.findItemById(model.id);
      options.config.goBackUp = !!(item && item.parent);

      if (this.graph.isMultiTree(model.id)) {
        options.config.closeNodeMap = true;
      }

      const correlations = this.graph.getNodeCorrelations(model.id);

      options.config.correlations =
        options.config.correlations || this.showCorrelationsNode;

      if (correlations.length > 0) {
        // 隐藏此功能 showCorrelationsNode = false
        if (this.showCorrelationsNode) {
          options.correlations = correlations;
        } else {
          options.config.correlations = false;
        }
      } else {
        options.config.correlations = false;
        options.config.showEdges = false;
      }

      if (this.$root.config.edgeEnabled !== "false") {
        options.config.link = false;
        options.config.link2 = false;
      }

      if (this.isTransfer) {
        options.config.openEditor = false;
        options.config.changeIconType = false;
        options.config.cut = false;
        options.config.copy = false;
        options.config.paste = false;
        options.config.hideParent = false;
        options.config.changeRoot = false;
        options.config.link = false;
        options.config.link2 = false;
        options.config.correlations = false;
        options.config.showEdges = false;
        options.config.quadtreeNodes = false;
        NodeMenus[2].line = false;
      }
      if (!this.isShowMDEditor()) {
        options.config.openEditor = true;
      }
      return getMenusDom(NodeMenus, this.graph, node, options);
    },
    getEdgeMenusDom(node) {
      if (!this.isTransfer) {
        return getMenusDom(EdgeMenus, this.graph, node);
      }
    },
    getCanvasMenusDom() {
      return getMenusDom(CanvasMenus, this.graph);
    },
    checkFloat(toolbar, e) {
      const el = toolbar.get("menu");
      if (el && el.childNodes && el.childNodes[0] && el.style.visibility === "visible") {
        const height = el.childNodes[0].offsetHeight;
        const width = el.childNodes[0].offsetWidth;
        const p = this.$refs.mindmap.offset();
        if (e.clientX + width - p.right > 0) {
          toolbar.set("offsetX", -width - 6);
        } else {
          toolbar.set("offsetX", 6);
        }
        if (e.clientY + height > document.body.clientHeight - 6) {
          toolbar.set("offsetY", -height);
        } else {
          toolbar.set("offsetY", 6);
        }
      }
    },
    addNodeMenu() {
      let vm = this;
      const toolbar = new Menu({
        className: "shadow bounceIn thin-border bgblur",
        shouldBegin: (e) => {
          const isCanvas = e?.target.isCanvas && e.target.isCanvas();
          const isEdge = e?.item?.get("type") === "edge";
          // console.log("当前模式", this.graph.getCurrentMode());
          // if (vm.mode === "default" && !isCanvas) return false; // 阅读模式不支持右键
          if (vm.mode === "connect" && !isEdge) return false; // 联系模式只支持连线和切换线条
          this.checkFloat(toolbar, e);
          return true;
        },
        getContent: (e) => {
          const isCanvas = e?.target.isCanvas && e.target.isCanvas();
          const isEdge = e?.item?.get("type") === "edge";
          // if (vm.mode === "default" && !isCanvas) return null; // 阅读模式不支持右键
          if (vm.mode === "connect" && !isEdge) return; // 联系模式只支持连线和切换线条
          if (!isCanvas && !isEdge) {
            return this.getNodeMenusDom(e.item);
          } else if (isEdge) {
            return this.getEdgeMenusDom(e.item);
          }
          return this.getCanvasMenusDom();
        },
        itemTypes: ["node", "canvas", "edge"],
        handleMenuClick: (target, item) => {
          const code = target.getAttribute("code");
          if (code === "correlations" || code === "quadtreeNodes") {
            return;
          }
          let node = item;
          if (code === "correlation") {
            node = this.graph.findById(target.getAttribute("node-id"));
          } else if (code === "subQuadtreeNodes") {
            const nodeId = target.getAttribute("node-id");
            this.$emit(EventName.change, {
              type: MindmapEvent.openQuadtree,
              options: {
                id: nodeId,
              },
            });
            return;
          }
          // this.$emit(EventName.change, {
          //   type: MindmapEvent.nodeSelect,
          //   options: { node },
          // });
          this.handleClickCode(code, node);
        },
      });
      // this.graph.addPlugin(toolbar);
      return toolbar;
    },
    addToolbar() {
      const toolbarRef = this.$refs.toolbarRef;
      const toolbar = new ToolBar({
        className: toolbarRef.className,
        getContent: () => toolbarRef,
        handleClick: this.handleClickCode,
      });
      // this.graph.addPlugin(toolbar);
      this.toolbar = toolbar;
      return toolbar;
    },
    addMindmap() {
      const minimap = new Minimap({
        size: [100, 100],
        className: "mindmap-miniGap",
        viewportClassName: "mindmap-miniGap-viewPort",
        type: "delegate",
        delegateStyle: {
          fill: "#003a8c",
        },
      });
      // this.graph.addPlugin(minimap);
      return minimap;
    },
    handleClickCode(code, node, value) {
      const graph = this.graph;
      if (MenuActions[code]) {
        MenuActions[code].handler(this, graph, { node, value });
      }
      // if (code === "doubleArrow" || code === "nodeChange") {
      //   this.changeArrowType({node});
      // } else if (code === "leftArrow") {
      //   this.changeArrowType({node}, -1);
      // } else if (code === "rightArrow") {
      //   this.changeArrowType({node}, 1);
      // } else if (code === "deleteArrow") {
      //   this.deleteArrow({node});
      // } else if (code === "link") {
      //   graph.editSelectLink(node);
      // } else if (code === "link2") {
      //   graph.editSelectLink(node, 2);
      // } else if (code === "fitCenter") {
      //   // 在渲染和动画完成后调用
      //   graph.editFitCenter();
      //   return; //
      // } else if (code === "zoomIn") {
      //   graph.editZoomIn();
      //   return;
      // } else if (code === "zoomOut") {
      //   graph.editZoomOut();
      //   return;
      // } else if (code === "downloadPage") {
      //   this.tempData = this.graph.get("data");
      //   this.showDownload = true;
      //   return;
      // } else if (code === "exportFile") {
      //   //
      // } else if (code === "addChild") {
      //   this.$emit(EventName.change, {
      //     type: MindmapEvent.nodeAdd,
      //     options: {node, sort: value},
      //   });
      //   return;
      // } else if (code === "cut") {
      //   cut(this.graph._curSelectedNode);
      //   this.$root.tips("success", "已剪切");
      //   return;
      // } else if (code === "closeMap") {
      //   const articleId = node.getModel().id;
      //   this.graph.removeQuadtreeData(articleId);
      //   this.$emit(EventName.change, {
      //     type: "closeSameMap",
      //     options: {
      //       id: articleId
      //     },
      //   });
      //   this.graph.editFitCenter();
      //   return;
      // } else if (code === "copy") {
      //   copy(node);
      //   this.$root.tips("success", "已复制");
      //   return;
      // } else if (code === "paste") {
      //   this.$emit(EventName.change, {
      //     type: "paste",
      //     options: {
      //       nodes: getCopiedNodes(),
      //       parent: node,
      //     },
      //   });
      //   return;
      // } else if (code === "delete") {
      //   const model = node.getModel();
      //   if (this.isTransfer) {
      //     if (model.sortId === "0-0") {
      //       setTimeout(() => {
      //         this.$emit("close");
      //       }, 500);
      //     }
      //     this.onDeleteNode(model.id);
      //   } else {
      //     this.$root.showConfirm({
      //       title: "确认删除",
      //       subtitle: "删除" + model.title,
      //       onConfirm: () => {
      //         this.onDeleteNode(model.id);
      //       },
      //     });
      //   }

      //   // this.graph.editDeleteNode(node);
      //   return;
      // } else if (code === "collapsed") {
      //   graph.menuExpand(node, true);
      //   graph.editFitCenter();
      //   return;
      // } else if (code === "expand") {
      //   graph.menuExpand(node, false);
      //   graph.editFitCenter();
      //   return;
      // } else if (code === "expandAll") {
      //   this.expandAll(node);
      //   return;
      // } else if (code === "edgeDelete") {
      //   graph.deleteEdge(node);
      // } else if (code === "labelDelete") {
      //   graph.edgeDeleteLabel(node);
      // } else if (code === "labelEdit") {
      //   this.modifyEdge(node);
      // } else if (code === "correlation") {
      //   if (this.correlationChangeToRoot) {
      //     graph.editChangeRoot(node.getModel().id);
      //   } else {
      //     const model = node.getModel();
      //     const edges = graph.getNodeAndChildrenEdges(node);
      //     this.$emit(EventName.change, {
      //       type: MindmapEvent.nodeCorrelation,
      //       options: {
      //         model,
      //         edges,
      //       },
      //     });
      //     return;
      //   }
      // } else if (code === "changeRoot") {
      //   graph.editChangeRoot(node.getModel().id);
      // } else if (code === "labelAdd") {
      //   graph.edgeEditLabel(node);
      // } else if (code === "hideEdge") {
      //   // this.hideEdge = !this.hideEdge;
      //   if (
      //       this.$root.config.mindHideEdgeLink &&
      //       this.$root.config.mindHideEdgeLink === "false"
      //   ) {
      //     this.$root.config.mindHideEdgeLink = "true";
      //   } else {
      //     this.$root.config.mindHideEdgeLink = "false";
      //   }
      //   // graph.setEdgeVisible(this.hideEdge);
      // } else if (code === "showEdges") {
      //   let p = graph.getShowEdgesAndChildrens(node);
      //   if (this.showEdgesChangeRoot) {
      //     this.$emit("update:mode", "default");
      //     this.$emit("update:edges", []);
      //     this.$emit("update:modelValue", p);
      //   } else {
      //     this.$emit(EventName.change, {
      //       type: MindmapEvent.nodeShowEdges,
      //       options: {p},
      //     });
      //   }
      // } else if (code === "changeMindStyle") {
      //   this.changeMindStyleVisible = true;
      //   setTimeout(() => {
      //     this.$refs.mindStyleRef.restore();
      //   }, 200);
      //   return;
      // } else if (code === "changeEdgeShowLabel") {
      //   this.$emit(EventName.change, {
      //     type: MindmapEvent.edgeClickShowLabel,
      //     options: null,
      //   });
      //   return;
      // } else if (code === "back") {
      //   this.$emit(EventName.change, {
      //     type: "back",
      //     options: null,
      //   });
      //   return;
      // } else if (code === "openCurMindMap") {
      //   this.$emit(EventName.change, {
      //     type: "openCurMindMap",
      //     options: null,
      //   });
      //   return;
      // } else if (code === "setting") {
      //   this.$root.configActive = "mind";
      //   this.$root.configVisible = true;
      //   return;
      // } else if (code === "changeIconType") {
      //   let model = node.getModel();
      //   // this.mindChangeIconType(model.id, iconType, model);
      //   this.$emit(EventName.change, {
      //     type: "changeIconType",
      //     options: {model: model},
      //   });
      //   return;
      // } else if (code === "hideToolbar") {
      //   this.barExpand = false;
      // } else if (code === "openEditor") {
      //   if (this.mode === 'default') {
      //     this.$emit(EventName.change, {
      //       type: "openEditor",
      //       options: {model: node.getModel()},
      //     });
      //   } else {
      //     this.$root.setSelectItem(this.$root.findItemById(node.getModel().id));
      //     this.openEditor();
      //   }
      // } else if (code === "hideParent") {
      //   this.graph.hideParent(node);
      // } else if (code === "aiRelatedWords") {
      //   this.$emit(EventName.change, {
      //     type: "aiRelatedWords",
      //     options: {node, sort: value},
      //   });
      // } else if (code === "aiExSentence") {
      //   this.$emit(EventName.change, {
      //     type: "aiExSentence",
      //     options: {node, sort: value},
      //   });
      // } else if (code === "aiTranslate") {
      //   this.$emit(EventName.change, {
      //     type: "aiTranslate",
      //     options: {node, sort: value},
      //   });
      // } else if (code === "insertEditor") {
      //   this.getDataURL();
      //   return;
      // }
      this.toolbar.handleDefaultOperator(code, graph);
    },
    async onDeleteNode(articleId) {
      if (!articleId) return;
      let item = this.nodeSelectRecord.pop();
      while (item && (!this.$root.findItemById(item.id) || item.id !== articleId)) {
        item = this.nodeSelectRecord.pop();
      }
      this.$root.delayInputFocus();
      const node = this.graph.findById(articleId);

      if (node) {
        this.graph.editDeleteNode(node);
      }
      if (this.nodeSelectRecord.length > 0) {
        const ids = this.$root.getChildrenIds(articleId);
        ids.forEach((id) => {
          const idx = this.nodeSelectRecord.findIndex((item) => item.id === id);
          if (idx !== -1) {
            this.nodeSelectRecord.splice(idx, 1);
          }
        });
        const preItem = this.nodeSelectRecord[this.nodeSelectRecord.length - 1];
        if (preItem) {
          item = this.$root.findItemById(preItem.id);
          if (item) {
            this.$root.setSelectItem(item);
          }
        }
      }
    },
    hideMenu() {
      this.menuType = 0;
      if (this.$refs.nodeMenuRef) {
        this.$refs.nodeMenuRef.style.display = "none";
      }
    },
    showMenu(node) {
      if (!node || !this.isMobile) return;
      clearTimeout(this.closeTimer);
      console.log("---showmenu");
      this.menuType = 0;
      const toolbarRef = this.$refs.nodeMenuRef;
      toolbarRef.style.display = "block";

      const model = node.getModel();
      if (model.type === NodeType.defaultNode) {
        const { x: pointX, y: pointY } = node.getBBox();
        const {
          style: { stroke, beforeWidth, width },
          btnStyle,
        } = model;
        const { x, y } = this.graph.getClientByPoint(pointX, pointY);
        const ratio = this.graph.getZoom();
        const rect = this.$refs.mindmap.getBoundingClientRect();
        const p = this.$refs.mindGraph.getBoundingClientRect();
        const left =
          beforeWidth * ratio +
          x -
          stroke * 0.5 * ratio -
          (toolbarRef.clientWidth - width * ratio) * 0.5;
        toolbarRef.style.left = `${left - p.left}px`;
        toolbarRef.style.top = `${
          y - btnStyle.lineWidth * 0.5 * ratio - toolbarRef.clientHeight - 4 - rect.top
        }px`;
      }
    },
    showLabelMenu({ node, edge }) {
      if (!this.isMobile) return;
      clearTimeout(this.closeTimer);
      setTimeout(() => {
        this.menuType = 1;
        this.$nextTick(() => {
          this.tempNode = edge;
          const attrs = node.get("attrs");
          const pos = this.graph.getClientByPoint(attrs.x, attrs.y);
          const box = node.getBBox();
          const toolbarRef = this.$refs.nodeMenuRef;
          toolbarRef.style.display = "block";
          toolbarRef.style.left = `${
            pos.x - (toolbarRef.clientWidth - box.width) * 0.5
          }px`;
          toolbarRef.style.top = `${pos.y - toolbarRef.clientHeight - 4}px`;
        });
      }, 20);
    },
    mindChangeIconType(id = null, iconDetail) {
      this.graph.updateModel(id, { iconDetail });
    },
    initGraph() {
      if (this.graph || !this.modelValue || !this.$refs.mindmap) return;
      clearTimeout(this.tempTimer);
      this.graph?.destroy();
      this.graph = this.createGraph(this.$props);
      if (this.graph) {
        this.graph.on(EventName.change, (evt) => {
          if (evt.type === MindmapEvent.labelClick) {
            if (this.isMobile) {
              this.onCancelMenu();
              window.removeEventListener("click", this.onCancelMenu);
              this.tempNode = evt.options.edge;
              this.showNodeMenu = true;
            }
          } else if (evt.type === MindmapEvent.canvasClick) {
            if (this.isMobile) {
              this.hideMenu();
              this.tempNode = undefined;
              this.showNodeMenu = false;
              this.onCancelMenu();
              window.removeEventListener("click", this.onCancelMenu);
            }
          } else if (evt.type === MindmapEvent.nodeSelect) {
            if (this.clickTimer) {
              clearTimeout(this.clickTimer);
              this.clickTimer = undefined;
            }
            this.clickTimer = setTimeout(() => {
              this.clickTimer = undefined;
            }, 500);
            if (this.isMobile) {
              this.onCancelMenu();
              window.removeEventListener("click", this.onCancelMenu);
              this.tempNode = evt.options.node;
              this.showNodeMenu = true;
            }
            this.$emit(EventName.change, evt);
          } else if (evt.type === MindmapEvent.edgeClick) {
            if (this.isMobile) {
              this.onCancelMenu();
              window.removeEventListener("click", this.onCancelMenu);
              this.tempNode = evt.options.node;
              this.showNodeMenu = true;
            }
          } else if (evt.type === MindmapEvent.edgeCreate) {
            this.createEdge(evt.options);
          } else if (evt.type === MindmapEvent.dragExternal) {
            this.moveArticle(evt.options);
          } else if (evt.type === MindmapEvent.canvasMove) {
            this.hideMenu();
          } else if (evt.type === MindmapEvent.dragNode) {
            this.$root.tempMindDragNode = evt.options;
          } else if (evt.type === MindmapEvent.mobileClickNode) {
            this.hideMenu();
            clearTimeout(this.showTimer);
            this.showTimer = setTimeout(() => {
              this.showMenu(this.graph._curSelectedNode);
            }, 300);
          } else if (evt.type === MindmapEvent.focus) {
            clearTimeout(this.showTimer);
            this.hideMenu();
          } else {
            this.$emit(EventName.change, evt);
          }
        });
        if (!this.$root.mindMapCaches[this.modelValue.id]) {
          this.$root.mindMapCaches[this.modelValue.id] = { root: true };
        }
        const cache = this.$root.mindMapCaches[this.modelValue.id];
        this.graph.updateData(this.modelValue, this.edges, cache);
        this.initTools();
        if (this.selectItem) {
          this.tempTimer = setTimeout(() => {
            this.graph?.editSelectedNodeById(this.selectItem.id);
          }, 50);
        }
      }
      this.graph.selectEditEnabled = !this.$root.cursorElement;
    },
    async cloneArticle(options) {
      console.log("--cloneArticle-options--", options);
      // if (!options.external) return;
      // const item = this.$root.findItemById(options.external.id);
      // const parent = this.$root.findItemById(options.parentId);
      // if (!item || !parent) return;
      // this.saveStatus("保存中");
      // const s = await this.$root.cloneArticle(item, parent, options.sort);
      // this.graph?.cloneTo(s, options.sort);
      // this.saveStatus("已保存到云端");
    },
    createGraph(layoutConfig) {
      ///
      if (!this.$refs.mindmap) return;
      let vm = this;
      const plugins = [this.addToolbar()];
      if (this.mode !== "default") {
        plugins.push(this.addNodeMenu());
      }
      const config = {
        width: this.$refs.mindmap.offsetWidth,
        height: this.$refs.mindmap.offsetHeight,
        plugins: plugins,
        layout: {
          type: "mindmap",
          direction: "H",
          getHeight: (node) => {
            return node.style?.height;
          },
          getWidth: (node) => {
            return node.style.visible ? node.style?.width : 0;
          },
          getVGap: () => {
            return this.$props.vGap || 8;
          },
          getHGap: (node) => {
            return node.style.visible
              ? (this.$props.hGap || 25) + (node.style.beforeWidth || 0)
              : 0;
            // return node.style.visible ? this.$props.hGap || 25 : 0;
          },
          getSide: (node) => {
            return node.data.side || "right";
          },
        },
        defaultNode: {
          type: NodeType.defaultNode,
          linkPoints: {
            top: true,
            bottom: true,
            left: true,
            right: true,
          },
        },
        defaultEdge: {
          type: layoutConfig?.sharpCorner ? "round-poly" : "cubic-horizontal",
          style: this.defaultEdgeStyle,
        },
        modes: {
          default: vm.mode === "edit" ? this.editMode : this.defaultMode,
        },
        groupByTypes: false,
        enableStack: true,
        animate: false,
        renderer: "canvas",
      };
      return new MindGraph(
        {
          ...config,
          container: this.$refs.mindmap,
        },
        {
          editEl: this.$refs.mindmapInput,
          config: layoutConfig,
          hideEdge: this.hideEdge,
          nodeStyle: this.defaultNodeStyle,
          edgeLinkStyle: this.defaultEdgeLinkStyle,
          initShowDepth: this.initShowDepth,
          clickEdgeShowLabel: this.clickEdgeShowLabel,
          isMobile: this.isMobile,
          isDoubleEdit: this.isMobile,
          quadtreeType: this.quadtreeType,
          links: this.$root.links,
          mode: this.mode,
          shortcuts: this.shortcuts,
          hideDragExternalNode: true,
        }
      );
    },
    changeMindTheme() {
      this.graph.changeTheme({
        edgeStyle: this.defaultEdgeStyle,
        nodeStyle: this.defaultNodeStyle,
        edgeLinkStyle: this.defaultEdgeLinkStyle,
        clickEdgeShowLabel: this.clickEdgeShowLabel,
      });
    },
    showEdges() {
      const node = this.graph._curSelectedNode;
      if (!node) {
        this.$root.tips("warning", "未选择模型");
        return;
      }
      const model = node.getModel();
      const correlations = this.graph.getNodeCorrelations(model.id);
      if (correlations.length > 0) {
        this.handleClickCode("showEdges", node);
      } else {
        this.$root.tips("warning", "当前模型还没有设置双链");
      }
    },
    editItem() {
      const timer = setTimeout(() => {
        this.graph.editItem(this.graph._curSelectedNode);
        clearTimeout(timer);
      }, 0);
    },
    async expandAll(node) {
      this.graph.expandAll(node, false);
      // if (
      //     node?.getModel()?.children?.length ||
      //     node?.getModel()?._children?.length
      // ) {
      //   await this.graph.menuExpand(node, false);
      //   for (let i = 0; i < node._cfg.children.length; i++) {
      //     await this.expandAll(node._cfg.children[i]);
      //   }
      // }
    },
    async changeArrowType({ node }, orientation = 0) {
      const targetId = node.getTarget().getModel().id;
      const sourceId = node.getSource().getModel().id;
      await this.$root.changeEdgeConfig({ targetId, sourceId, orientation });
      this.graph.changeEdgeStyle(node, { arrowType: 1, orientation });
    },
    async deleteArrow({ node }) {
      const targetId = node.getTarget().getModel().id;
      const sourceId = node.getSource().getModel().id;
      await this.$root.deleteArrow({ targetId, sourceId });
      this.graph.changeEdgeStyle(node, { arrowType: 0 });
    },
    showToolbarEdgeMenu() {
      console.log(this.graph._curSelectedNode);
    },
  },
};
</script>
<style lang="scss">
.node-menu {
  position: absolute;
  z-index: 9;
  padding-bottom: 8px;
  display: none;
}

.node-menu-content {
  white-space: nowrap;
  display: flex;
  color: #eee;
  align-items: center;
  background-color: rgb(61, 61, 61, 0.95);
  border-radius: 6px;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.mb-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  transition: all 0.25s;
  font-size: 12px;

  &:active {
    background-color: #525252;
  }

  .svg-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
}
</style>
