<template>
  <div
    ref="content"
    :class="`drag-win drag-${direction} drag-min-${parseInt(
      Math.ceil(curWidth / 100) * 100
    )}`"
    :style="{ display: showMode !== 2 ? 'block' : 'none' }"
  >
    <slot />
    <div
      class="drag-line-common drag-line"
      v-if="dragEnabled"
      :class="{ resize: resizeAble, 'mxs-mobile-drag': isMobile }"
      @mousedown="onMouseDown"
      @touchstart="onMouseDown"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
  </div>
</template>

<script>
import { DragShowMode } from "../../../../utils/constaints";
import {Platform} from "../../../../utils/utils";
export default {
  name: "DragRight",
  props: {
    direction: { type: String, default: "right" },

    minWidth: { type: Number, default: 8 },
    maxWidth: { type: Number, default: 0 },
    initWidth: { type: [Number, String], default: 0 },

    minHeight: { type: Number, default: 8 },
    maxHeight: { type: Number, default: 0 },
    initHeight: { type: Number, default: 0 },
    showMode: { type: Number, default: 0 },
    dragEnabled: { type: Boolean, default: true },
  },
  data() {
    return {
      resizeAble: false,
      curWidth: 1000,
      isMobile: Platform.isMobile
    };
  },
  watch: {
    showMode(val, old) {
      if (val !== old) {
        this.resetSize();
      }
    },
    direction(val, old) {
      if (val !== old) {
        // this.initSize();
      }
    },
  },
  mounted() {
    this.resetSize();
  },
  methods: {
    getH(v) {
      if (!v) {
        return this.$refs.content.parentNode.offsetHeight;
      }
      if (typeof v === "string" && v.endsWith("%")) {
        return parseInt((this.$refs.content.parentNode.offsetHeight * parseInt(v)) / 100);
      }
      return parseInt(v);
    },
    getW(w) {
      if (!w) {
        return this.$refs.content.parentNode.offsetWidth;
      }
      if (typeof w === "string" && w.endsWith("%")) {
        return parseInt(
          ((this.$refs.content.parentNode.offsetWidth * parseInt(w)) / 100).toString()
        );
      }
      return parseInt(w);
    },
    resetSize() {
      if (this.showMode === DragShowMode.full) {
        if (this.direction === "right") {
          this.setWidthFlex(this.$refs.content.parentNode.offsetWidth, false);
        } else if (this.direction === "bottom") {
          this.setHeightFlex(this.$refs.content.parentNode.offsetHeight);
        }
      } else if (this.showMode === DragShowMode.default) {
        if (this.direction === "right") {
          let initW = this.sizeRate
            ? this.$refs.content.parentNode.offsetWidth * this.sizeRate
            : this.getW(this.initWidth);
          let minW = this.getW(this.minWidth);
          this.setWidthFlex(initW > minW ? initW : minW);
        } else {
          const initH = this.sizeRate
            ? this.$refs.content.parentNode.offsetHeight * this.sizeRate
            : this.getH(this.initHeight);
          const minH = this.getH(this.minHeight);
          let pH = this.$refs.content.parentNode.offsetHeight;
          this.setHeightFlex(initH > minH ? initH : pH);
        }
      } else {
        if (this.direction === "right" && this.$refs.content.offsetWidth > 0) {
          this.sizeRate =
            this.$refs.content.offsetWidth / this.$refs.content.parentNode.offsetWidth;
        } else if (this.direction === "top" && this.$refs.content.offsetHeight > 0) {
          this.sizeRate =
            this.$refs.content.offsetHeight / this.$refs.content.parentNode.offsetHeight;
        }
        this.setWidthFlex(0);
      }
    },
    setWidthFlex(w, next = true) {
      // let flex = w / this.$refs.content.parentNode.offsetWidth;
      // this.$refs.content.style.flex = `${flex} 1 0%`;
      // if (next && this.$refs.content.nextSibling) {
      //   this.$refs.content.nextSibling.style.flex = `${1 - flex} 1 0%`;
      // }
      this.$refs.content.style.width = w + "px";
      this.curWidth = w;
      if (next && this.$refs.content.nextSibling) {
        this.$refs.content.nextSibling.flex = 1;
      }
    },
    setHeightFlex(h) {
      this.$refs.content.style.height = h + "px";
      // let flex = w / this.$refs.content.parentNode.offsetHeight;
      // this.$refs.content.style.flex = `1 ${flex} 0%`;
      // if (next && this.$refs.content.nextSibling) {
      //   this.$refs.content.nextSibling.style.flex = `1 ${1 - flex} 0%`;
      // }
    },
    onMouseDown(e) {
      if (!this.dragEnabled) return;
      e.preventDefault();
      this.resizeAble = true;
      const { clientX, clientY } = e.touches && e.touches.length > 0 ? e.touches[0] : e;
      this.clientX = clientX;
      this.clientY = clientY;
      this.width = this.$refs.content.offsetWidth;
      this.height = this.$refs.content.offsetHeight;

      window.document.body.style.cursor =
        this.direction === "right" ? "col-resize" : "row-resize";
        if (!Platform.isMobile) {
          window.addEventListener("mousemove", this.onMouseMove);
          window.addEventListener("mouseup", this.onMouseUp, { once: true });
        }
    },
    onMouseMove(e) {
      if (!this.resizeAble) return;
      e.preventDefault();
      const { clientX, clientY } = e.touches && e.touches.length > 0 ? e.touches[0] : e;
      if (this.direction === "right") {
        let minWidth = this.getW(this.minWidth);
        let maxWidth = this.getW(this.maxWidth);
        
        let sW = this.width + clientX - this.clientX;
        if (sW < minWidth) {
          this.setWidthFlex(minWidth);
          return;
        }
        if (clientX !== this.clientX) {
          sW = sW < minWidth ? minWidth : sW;
          if (maxWidth) {
            sW = sW > maxWidth ? maxWidth : sW;
          }
          this.setWidthFlex(sW);
        }

        // this.clientX = clientY;
      } else if (this.direction === "top") {
        let minHeight = this.getW(this.minHeight);
        let maxHeight = this.getW(this.maxHeight);
        let sW = this.height + this.clientY - clientY;
        if (sW < minHeight) {
          this.setHeightFlex(minHeight);
          return;
        }
        if (clientY !== this.clientY) {
          sW = sW < minHeight ? minHeight : sW;
          sW = maxHeight && sW > maxHeight ? maxHeight : sW;
          this.setHeightFlex(sW);
        }
      } else {
        let minHeight = this.getH(this.minHeight);
        if (clientY < this.$refs.content.offset().top + minHeight) {
          this.$refs.content.style.height = minHeight - 8 + "px";
          this.clientY = clientY;
          return;
        }
        let maxHeight = this.getH(this.maxHeight);
        let mH = this.$refs.content.offsetHeight;
        let sH = mH + parseInt((clientY - this.clientY).toString());
        sH = sH < minHeight ? minHeight : sH;
        if (maxHeight) {
          sH = sH > maxHeight ? maxHeight : sH;
        }
        this.$refs.content.style.height = sH + "px";
        this.clientY = clientY;
      }
    },
    onMouseUp() {
      // console.log("-2222--e", this.resizeAble);
      this.resizeAble = false;
      window.document.body.style.cursor = "initial";
      window.removeEventListener("mousemove", this.onMouseMove);
    },
  },
};
</script>

<style scoped></style>
