import {
  EventName,
  MindmapEvent,
} from "../../../../components/antv-mindmap/mxs-mindmap.es";
import { copy, cut, getCopiedNodes } from "./copyNode";

export const MenuActions = {
  doubleArrow: {
    handler: (vm, graph, { node, value }) => {
      vm.changeArrowType({ node });
    },
  },
  nodeChange: {
    handler: (vm, graph, { node, value }) => {
      vm.changeArrowType({ node });
    },
  },
  leftArrow: {
    handler: (vm, graph, { node, value }) => {
      vm.changeArrowType({ node }, -1);
    },
  },
  rightArrow: {
    handler: (vm, graph, { node, value }) => {
      vm.changeArrowType({ node }, 1);
    },
  },
  deleteArrow: {
    handler: (vm, graph, { node, value }) => {
      vm.deleteArrow({ node });
    },
  },
  link: {
    handler: (vm, graph, { node, value }) => {
      graph.editSelectLink(node);
    },
  },
  link2: {
    handler: (vm, graph, { node, value }) => {
      graph.editSelectLink(node, 2);
    },
  },
  fitCenter: {
    handler: (vm, graph, { node, value }) => {
      // 在渲染和动画完成后调用
      graph.editFitCenter();
      return true;
    },
  },
  zoomIn: {
    handler: (vm, graph, { node, value }) => {
      graph.editZoomIn();
      return true;
    },
  },
  zoomOut: {
    handler: (vm, graph, { node, value }) => {
      graph.editZoomOut();
      return true;
    },
  },
  downloadPage: {
    handler: (vm, graph, { node, value }) => {
      vm.tempData = graph.get("data");
      vm.showDownload = true;
      return true;
    },
  },
  exportFile: {
    handler: (vm, graph, { node, value }) => {},
  },
  addChild: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: MindmapEvent.nodeAdd,
        options: { node, sort: value },
      });
      return true;
    },
  },
  cut: {
    handler: (vm, graph, { node, value }) => {
      cut(node);
      vm.$root.tips("success", "已剪切");
      return true;
    },
  },
  closeMap: {
    handler: (vm, graph, { node, value }) => {
      const articleId = node.getModel().id;
      vm.graph.removeQuadtreeData(articleId);
      vm.$emit(EventName.change, {
        type: "closeSameMap",
        options: {
          id: articleId,
        },
      });
      vm.graph.editFitCenter();
      return true;
    },
  },
  copy: {
    handler: (vm, graph, { node, value }) => {
      copy(node);
      vm.$root.tips("success", "已复制");
      return true;
    },
  },
  paste: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "paste",
        options: {
          nodes: getCopiedNodes(),
          parent: node,
        },
      });
      return true;
    },
  },
  delete: {
    handler: (vm, graph, { node, value }) => {
      const model = node.getModel();
      if (vm.isTransfer) {
        if (model.sortId === "0-0") {
          setTimeout(() => {
            vm.$emit("close");
          }, 500);
        }
        vm.onDeleteNode(model.id);
      } else {
        vm.$root.showConfirm({
          title: "确认删除",
          subtitle: "删除" + model.title,
          onConfirm: () => {
            vm.onDeleteNode(model.id);
          },
        });
      }
      return true;
    },
  },
  collapsed: {
    handler: (vm, graph, { node, value }) => {
      graph.menuExpand(node, true);
      graph.editFitCenter();
      return;
    },
  },
  expand: {
    handler: (vm, graph, { node, value }) => {
      graph.menuExpand(node, false);
      graph.editFitCenter();
      return true;
    },
  },
  expandAll: {
    handler: (vm, graph, { node, value }) => {
      vm.expandAll(node);
      return true;
    },
  },
  edgeDelete: {
    handler: (vm, graph, { node, value }) => {
      graph.deleteEdge(node);
    },
  },
  labelDelete: {
    handler: (vm, graph, { node, value }) => {
      graph.edgeDeleteLabel(node);
    },
  },
  labelEdit: {
    handler: (vm, graph, { node, value }) => {
      vm.modifyEdge(node);
    },
  },
  correlation: {
    handler: (vm, graph, { node, value }) => {
      if (vm.correlationChangeToRoot) {
        graph.editChangeRoot(node.getModel().id);
      } else {
        const model = node.getModel();
        const edges = graph.getNodeAndChildrenEdges(node);
        vm.$emit(EventName.change, {
          type: MindmapEvent.nodeCorrelation,
          options: {
            model,
            edges,
          },
        });
        return true;
      }
    },
  },
  changeRoot: {
    handler: (vm, graph, { node, value }) => {
      graph.editChangeRoot(node.getModel().id);
    },
  },
  labelAdd: {
    handler: (vm, graph, { node, value }) => {
      graph.edgeEditLabel(node);
    },
  },
  hideEdge: {
    handler: (vm, graph, { node, value }) => {
      if (
        vm.$root.config.mindHideEdgeLink &&
        vm.$root.config.mindHideEdgeLink === "false"
      ) {
        vm.$root.config.mindHideEdgeLink = "true";
      } else {
        vm.$root.config.mindHideEdgeLink = "false";
      }
    },
  },
  showEdges: {
    handler: (vm, graph, { node, value }) => {
      let p = graph.getShowEdgesAndChildrens(node);
      if (vm.showEdgesChangeRoot) {
        vm.$emit("update:mode", "default");
        vm.$emit("update:edges", []);
        vm.$emit("update:modelValue", p);
      } else {
        vm.$emit(EventName.change, {
          type: MindmapEvent.nodeShowEdges,
          options: { p },
        });
      }
    },
  },
  changeMindStyle: {
    handler: (vm, graph, { node, value }) => {
      vm.changeMindStyleVisible = true;
      setTimeout(() => {
        vm.$refs.mindStyleRef.restore();
      }, 200);
      return true;
    },
  },
  changeEdgeShowLabel: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: MindmapEvent.edgeClickShowLabel,
        options: null,
      });
      return true;
    },
  },
  back: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "back",
        options: null,
      });
      return true;
    },
  },
  openCurMindMap: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "openCurMindMap",
        options: null,
      });
    },
  },
  setting: {
    handler: (vm, graph, { node, value }) => {
      vm.$root.configActive = "mind";
      vm.$root.configVisible = true;
      return true;
    },
  },
  changeIconType: {
    handler: (vm, graph, { node, value }) => {
      let model = node.getModel();
      // vm.mindChangeIconType(model.id, iconType, model);
      vm.$emit(EventName.change, {
        type: "changeIconType",
        options: { model: model },
      });
      return true;
    },
  },
  hideToolbar: {
    handler: (vm, graph, { node, value }) => {
      vm.barExpand = false;
    },
  },
  openEditor: {
    handler: (vm, graph, { node, value }) => {
      if (vm.mode === "default") {
        vm.$emit(EventName.change, {
          type: "openEditor",
          options: { model: node.getModel() },
        });
      } else {
        vm.$root.setSelectItem(vm.$root.findItemById(node.getModel().id));
        vm.openEditor();
      }
    },
  },
  hideParent: {
    handler: (vm, graph, { node, value }) => {
      graph.hideParent(node);
    },
  },
  aiRelatedWords: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "aiRelatedWords",
        options: { node, sort: value },
      });
    },
  },
  aiExSentence: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "aiExSentence",
        options: { node, sort: value },
      });
    },
  },
  aiTranslate: {
    handler: (vm, graph, { node, value }) => {
      vm.$emit(EventName.change, {
        type: "aiTranslate",
        options: { node, sort: value },
      });
    },
  },
  insertEditor: {
    handler: (vm, graph, { node, value }) => {
      vm.getDataURL();
      return true;
    },
  },
  goBackUp: {
    handler: (vm, graph, { node, value }) => {
      const item = vm.$root.findItemById(node.getModel().id);
      if (item && item.parent) {
        vm.menuOpenMindMap({ item: item.parent });
      }
    },
  },
  toolbarEdgeMenu: {
    handler: (vm, graph, { node, value }) => {
      vm.showToolbarEdgeMenu();
    },
  },
};
