export const iconMaps = {
  99: {
    //树
    icon: "new-02",
  },
  0: {
    //果实
    icon: "new-04",
    className: "size-2",
  },
  2: {
    //森林
    icon: "new-01",
    // className: 'size-2 mr-1'
  },
  1: {
    //重要果实
    icon: "new-04",
    // className: 'size-2 svg-color-red',
    className: "size-2",
  },
  20: {
    //树干
    icon: "new-06",
  },
  default: {
    icon: "new-02",
  },
};

/*
 * 0 果实
 * 1 重要果实
 * 2 森林
 * 20 树干
 * 99 大树
 * */
