<template>
  <div class="tips">
    <div class="d-flex" @click="onExpand">
      <slot />
    </div>
    <div class="tips-float" v-if="isExpand">
      <slot name="float" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tips",
  data() {
    return {
      isExpand: false
    }
  },
  methods: {
    onExpand() {
      this.isExpand = !this.isExpand;
      if (this.isExpand) {
        setTimeout(()=>{
          window.document.addEventListener('click', ()=>{
            this.isExpand = false;
          }, {once: true})
        }, 100)
      }
    }
  }
}
</script>

<style scoped>

</style>
