<template>
  <div
    ref="content"
    class="drag-win drag-win-fixed"
    :class="{ 'no-float': noFixed }"
    :style="show ? '' : 'display:none;'"
  >
    <div
      @mousedown="onMouseDown($event, 'box')"
      @touchstart="onMouseDown($event, 'box')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
      @dblclick="onReset"
    >
      <slot name="header" />
    </div>
    <slot />
    <div
      class="drag-line-common drag-line-right"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'right' }"
      @mousedown="onMouseDown($event, 'right')"
      @touchstart="onMouseDown($event, 'right')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-line-common drag-line-right drag-line-left"
      v-if="dragEnabled"
      :class="{ resize: dragDirection === 'left' }"
      @mousedown="onMouseDown($event, 'left')"
      @touchstart="onMouseDown($event, 'left')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />

    <div
      class="drag-line-common drag-line-bottom"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'bottom' }"
      @mousedown="onMouseDown($event, 'bottom')"
      @touchstart="onMouseDown($event, 'bottom')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-line-common drag-line-bottom drag-line-top"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'top' }"
      @mousedown="onMouseDown($event, 'top')"
      @touchstart="onMouseDown($event, 'top')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-angle drag-angle-left-top"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'left-top' }"
      @mousedown="onMouseDown($event, 'left-top')"
      @touchstart="onMouseDown($event, 'left-top')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-angle drag-angle-left-bottom"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'left-bottom' }"
      @mousedown="onMouseDown($event, 'left-bottom')"
      @touchstart="onMouseDown($event, 'left-bottom')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-angle drag-angle-right-top"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'right-top' }"
      @mousedown="onMouseDown($event, 'right-top')"
      @touchstart="onMouseDown($event, 'right-top')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
    <div
      class="drag-angle drag-angle-right-bottom"
      v-if="dragEnabled && !noFixed"
      :class="{ resize: dragDirection === 'right-bottom' }"
      @mousedown="onMouseDown($event, 'right-bottom')"
      @touchstart="onMouseDown($event, 'right-bottom')"
      @touchmove="onMouseMove"
      @touchend="onMouseUp"
    />
  </div>
</template>

<script>
import { Platform } from '../../../../utils/utils';

export default {
  name: "DragView",
  props: {
    show: { type: Boolean, default: true },
    noFixed: { type: Boolean, default: false },
    direction: { type: String, default: "left-right" },
    minWidth: { type: [Number, String], default: 280 },
    maxWidth: { type: [Number, String], default: "100%" },
    initWidth: { type: [Number, String], default: 280 },

    minHeight: { type: [Number, String], default: 320 },
    maxHeight: { type: [Number, String], default: "100%" },
    initRight: { type: [Number, String], default: 0 },
    initTop: { type: [Number, String], default: 0 },
    initBottom: { type: [Number, String], default: 0 },

    dragEnabled: { type: Boolean, default: true },
    viewWidth: { type: [Number, String], default: 280 },
  },
  data() {
    return {
      dragDirection: "",
    };
  },
  watch: {
    // show(val, old) {
    //   if (val !== old) {
    //     this.resetSize();
    //   }
    // },
    noFixed(val, old) {
      if (val !== old) {
        this.resetSize();
      }
    },
  },
  mounted() {
    this.resetSize();
  },
  methods: {
    getW(w) {
      if (!w) {
        return 0;
      }
      if (typeof w === "string" && w.endsWith("%")) {
        return parseInt(
          ((this.$refs.content.parentNode.offsetWidth * parseInt(w)) / 100).toString()
        );
      }
      return parseInt(w);
    },
    getH(h) {
      if (!h) {
        return 0;
      }
      if (typeof h === "string" && h.endsWith("%")) {
        return parseInt(
          ((this.$refs.content.parentNode.offsetHeight * parseInt(h)) / 100).toString()
        );
      }
      return parseInt(h);
    },
    setLeft(left) {
      this.left = left;
      this.$refs.content.style.left = left + "px";
    },
    setRight(right) {
      this.right = right;
      this.$refs.content.style.right = right + "px";
    },
    setTop(top) {
      this.top = top;
      this.$refs.content.style.top = top + "px";
    },
    setBottom(bottom) {
      this.bottom = bottom;
      this.$refs.content.style.bottom = bottom + "px";
    },
    setHeight(height) {
      this.height = height;
      this.$refs.content.style.height = height + "px";
    },
    setWidth(width) {
      this.preWidth = width;
      this.$refs.content.style.width = width + "px";
    },
    autoSize() {
      this.$refs.content.style.height = "auto";
      this.$refs.content.style.width = "auto";
      this.$refs.content.style.left = "auto";
      this.$refs.content.style.right = "auto";
      this.$refs.content.style.top = "auto";
      this.$refs.content.style.bottom = "auto";
    },
    resetSize() {
      if (!this.show) return;
      const minW = this.getW(this.minWidth);
      const maxW = this.getW(this.maxWidth);
      if (this.noFixed) {
        this.autoSize();
        const initW = this.preWidth || this.getW(this.initWidth);
        this.setWidth(initW < minW ? minW : initW > maxW ? maxW : initW);
      } else {
        const initW = this.width ? this.width : this.getW(this.initWidth);
        const initR = this.right ? this.right : this.getW(this.initRight);
        this.setRight(initR);
        const w = initW < minW ? minW : initW > maxW ? maxW : initW;
        const left = this.$refs.content.parentNode.offsetWidth - initR - w;
        this.setLeft(this.left || left);
        this.$refs.content.style.width = "auto";
        const initT = this.top ? this.top : this.getW(this.initTop);
        this.setTop(initT);
        const initB = this.bottom ? this.bottom : this.getW(this.initBottom);
        this.setBottom(initB);
        this.$refs.content.style.height = "auto";
      }
    },
    onReset() {
      this.width = 0;
      this.right = 0;
      this.left = 0;
      this.top = 0;
      this.bottom = 0;
      this.resetSize();
    },
    onMouseDown(e, dir) {
      if (!this.dragEnabled) return;
      if (!Platform.isMobile) {
        e.preventDefault();
      }
      
      const { clientX, clientY } = e.touches && e.touches.length > 0 ? e.touches[0] : e;
      this.clientX = clientX;
      this.clientY = clientY;

      this.dragWidth = this.$refs.content.offsetWidth;
      this.dragLeft = parseInt(this.$refs.content.style.left);
      this.dragRight = parseInt(this.$refs.content.style.right);
      this.dragTop = parseInt(this.$refs.content.style.top);
      this.dragBottom = parseInt(this.$refs.content.style.bottom);
      this.dragDirection = dir;
      this.$refs.content.style.transition = "none";
      const cursors = {
        right: "col-resize",
        left: "col-resize",
        top: "row-resize",
        bottom: "row-resize",
        "left-top": "se-resize",
        "right-top": "sw-resize",
        "left-bottom": "sw-resize",
        "right-bottom": "se-resize",
        box: "auto",
      };
      window.document.body.style.cursor = cursors[this.dragDirection];
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp, { once: true });
    },
    onMouseMove(e) {
      if (!this.dragDirection) return;
      if (!Platform.isMobile) {
        e.preventDefault();
      }
      const { clientX, clientY } = e.touches && e.touches.length > 0 ? e.touches[0] : e;
      if (this.noFixed) {
        if (this.dragDirection === "left") {
          let width = this.dragWidth + this.clientX - clientX;
          const minWidth = this.getW(this.minWidth);
          const maxWidth = this.getW(this.maxWidth);
          width = width < minWidth ? minWidth : width;
          width = width > maxWidth ? maxWidth : width;
          this.setWidth(width);
        }
      } else {
        if (this.dragDirection.includes("left")) {
          const minWidth = this.getW(this.minWidth);
          const maxWidth = this.getW(this.maxWidth);
          let left = this.dragLeft + clientX - this.clientX;
          left = left < 0 ? 0 : left;
          let sW = this.$refs.content.parentNode.offsetWidth - left - this.dragRight;
          if (sW < minWidth) {
            left = this.$refs.content.parentNode.offsetWidth - minWidth - this.dragRight;
            left = left < 0 ? 0 : left;
          } else if (clientX !== this.clientX) {
            if (sW > maxWidth) {
              left =
                this.$refs.content.parentNode.offsetWidth - maxWidth - this.dragRight;
              left = left < 0 ? 0 : left;
            }
          }
          this.setLeft(left);
        }
        if (this.dragDirection.includes("right")) {
          const minWidth = this.getW(this.minWidth);
          const maxWidth = this.getW(this.maxWidth);
          let right = this.dragRight + this.clientX - clientX;
          right = right < 0 ? 0 : right;
          let sW = this.$refs.content.parentNode.offsetWidth - right - this.dragLeft;
          if (sW < minWidth) {
            right = this.$refs.content.parentNode.offsetWidth - minWidth - this.dragLeft;
            right = right < 0 ? 0 : right;
          } else if (clientX !== this.clientX) {
            if (sW > maxWidth) {
              right =
                this.$refs.content.parentNode.offsetWidth - maxWidth - this.dragLeft;
              right = right < 0 ? 0 : right;
            }
          }
          this.setRight(right);
        }
        if (this.dragDirection.includes("top")) {
          const minHeight = this.getH(this.minHeight);
          const maxHeight = this.getH(this.maxHeight);
          let top = this.dragTop + clientY - this.clientY;
          top = top < 0 ? 0 : top;
          let sH = this.$refs.content.parentNode.offsetHeight - top - this.dragBottom;
          if (sH < minHeight) {
            top =
              this.$refs.content.parentNode.offsetHeight - minHeight - this.dragBottom;
            top = top < 0 ? 0 : top;
          } else if (clientY !== this.clientY) {
            if (sH > maxHeight) {
              top =
                this.$refs.content.parentNode.offsetHeight - maxHeight - this.dragBottom;
              top = top < 0 ? 0 : top;
            }
          }
          this.setTop(top);
        }
        if (this.dragDirection.includes("bottom")) {
          const minHeight = this.getH(this.minHeight);
          const maxHeight = this.getH(this.maxHeight);
          let bottom = this.dragBottom + this.clientY - clientY;
          bottom = bottom < 0 ? 0 : bottom;
          let sH = this.$refs.content.parentNode.offsetHeight - bottom - this.dragTop;
          if (sH < minHeight) {
            bottom =
              this.$refs.content.parentNode.offsetHeight - minHeight - this.dragTop;
            bottom = bottom < 0 ? 0 : bottom;
          } else if (clientY !== this.clientY) {
            if (sH > maxHeight) {
              bottom =
                this.$refs.content.parentNode.offsetHeight - maxHeight - this.dragTop;
              bottom = bottom < 0 ? 0 : bottom;
            }
          }
          this.setBottom(bottom);
        } else if (this.dragDirection === "box") {
          const dragX = clientX - this.clientX;
          const dragY = clientY - this.clientY;
          let left = this.dragLeft + dragX;
          let right = this.dragRight - dragX;
          let top = this.dragTop + dragY;
          let bottom = this.dragBottom - dragY;
          this.setLeft(left);
          this.setRight(right);
          this.setTop(top);
          this.setBottom(bottom);
        }
      }
      this.$emit("update:viewWidth", this.$refs.content.offsetWidth);
    },
    onMouseUp() {
      this.dragDirection = "";
      window.document.body.style.cursor = "initial";
      this.$refs.content.style.transition = "initial";
      window.removeEventListener("mousemove", this.onMouseMove);
    },
  },
};
</script>

<style scoped></style>
